

.darker-grotesque {
  font-family: "Darker Grotesque";
  font-optical-sizing: auto;
  font-style: normal;
}


.product_card {
    display: flex;
    flex-direction: column;
    height: 404px;
    width: 300px;
    padding: 0rem;
    border-radius: 30px;
    backdrop-filter: blur(20px);
    box-shadow: 0 0 400px rgba(0, 0, 0, 0.2), inset 0 0 30px rgba(255, 255, 255, 0.5);
}


.product_card .top_card {
    background-color: rgb(212, 213, 214);
    height: 45%;
    position: relative;
    border-radius: 1.9rem 1.9rem 0 0;   

}

.top_card .product_image {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 1.0rem 1.0rem 0 0;    

}
.product_card .bottom_card {
    /* background-image: linear-gradient(to top, #4B6D97, #cbd0d5); */
    background-color: rgb(255, 255, 255);
    height: 55%;
    border-radius: 0 0 1.9rem 1.9rem;   

}

.bottom_card .product_info{
    text-align: right;
    padding-right: 2.5rem;
    padding-top: 3.4rem;
    width: 110%;
    
}

.bottom_card .order_box {
    text-align: left;
    padding-left: 1rem;

}

.bottom_card h3 {
    font-family: "Bruno";
    font-weight: 100;
    color: rgb(16, 4, 4);
    font-size: 1.96rem;
}

.bottom_card h4 {
    color: #1978E6;
    font-size: 1.87rem;
    font-family: "Darker Grotesque", sans-serif;
    font-weight: 900;
    margin-top: -1rem;
    letter-spacing: 1.6px;
}

.bottom_card h6 {
    font-family: "Darker Grotesque", sans-serif;
    font-weight: 200;  
    font-size: 0.8rem;
    margin-top: -2rem;
    margin-left: 2rem;
    font-optical-sizing: auto;
}

.bottom_card button {
    position: relative;
    left: 7.9rem;
    top: -5.1rem;
    bottom: .2rem;
    font-size: 1.3rem;
    width: 50%;
    background-color: #1978E6;
    font-family: "Darker Grotesque", sans-serif;
    font-variant-caps: normal;
    font-weight: lighter;
    color: white;
    padding: 0.8rem;
    border-radius: 0.8rem;
    border: none;
    box-shadow: none;
    box-shadow: 0 0 0px none inset 0 0 0px none;

}




.package_information li {
    list-style-type: none; /* Remove bullet points for list items with class package_information */
    color: rgba(255, 255, 255, 0.474) !important; /* Set text color */
    font-size: 1.0rem; /* Set font size */
    padding-left: 1.3rem; /* Set left padding */
    margin: 1rem; /* Set margin */
    text-decoration: none; /* Remove underline */
}



.container_product_card {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 0.1fr 1fr 1fr 1fr;
    padding: 7em 1em 7em 1em;
}





@media screen and (max-width: 768px) {

    .container_product_card {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 1em 1em 5em 1em;   
         
    }

    .product_card {
        display: flex;
        flex-direction: column;
        height: 404px;
        width: 300px;
        margin-top: 4rem;
        
    }

    .bottom_card button {
        position: relative;
        left: 8.2rem;
        top: -5.1rem;
        border-radius: 1rem 1rem 1.2rem 1rem;
        box-shadow: none;
        font-family: "Darker Grotesque", sans-serif;
    }

}
/* Styles for the header */
header {
    background: none; /* Remove background properties */
    display: flex;
    justify-content: center;
    object-fit: cover;
    align-items: center;
    position: relative;
    overflow: hidden; /* Hide overflowing content */
    
    
  }

.logo {
  height: 3rem;
  margin-right: 1rem;
  
}

/* Style the logo container at the top */
.logo-container {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  top: 10px;
  z-index: 4; /* Ensure the logo is on top */
  padding: 4rem;
  margin-top: -3.4rem;
  position: absolute;
  display: grid;
  grid-template-columns: 0.1fr 2fr 0.1fr;

}

.menu-icon {
  margin-right: -2rem;
  margin-top: -14px;
  font-size: 3rem;
  color: whitesmoke;
  position: relative;
}


/* Initially hide the menu */
.menu {
  font-family: "Bruno";
  display: none;
  position: absolute;
  top: 7rem;
  right: 4.9rem;
  background-color: rgb(224, 224, 224);
  padding: 1.2rem;
  box-shadow: 14px -10px 18px rgba(0, 0, 0, 0.1);
  border-radius: 1rem 0rem 1rem 1rem;
  text-decoration: none;
  color: rgba(51, 46, 46, 0);
  transition: all 5s ease;
}
.menu li {
  font-size: 1.5rem;
  padding: 2rem;
  color: none;
  text-decoration: none;
  text-align: right;
}
.menu a {
  text-decoration: none;
  color: #2B2833;
}

/* Show the menu when the active class is applied */
.menu.active {
  display: block;
  opacity: 1;
}




  .header-navigation {
    width: 100%;
    display: grid;
    grid-template-columns: 4fr 1fr 1fr 1fr 1fr;
    justify-content: space-evenly;
    align-items: right;
    color: #ffffff92;
    list-style: none;
    z-index: 4;

    
  }
  .header-navigation-tabs{
    padding: 4rem;
    color: #ffffff;
    z-index: 4;
  }

.header-navigation-tabs a {
  color: inherit; /* Inherit color from parent */
  text-decoration: none; /* Remove underline */
}

.NavbarItems{
 
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: #fff;
}

.NavbarItems a {
  color: inherit; /* Inherit color from parent */
  text-decoration: none; /* Remove underline */
}
  
  /* Style the media container that holds both the image and video */
  .media-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh; /* Adjust the height based on your preference */
    overflow: hidden;
  }
  
  /* Style the background image */
  .background-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the container */
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1; /* Place the image below the video */
    overflow: hidden;
  }
  
  /* Style the video to be initially hidden */
  video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the video covers the container */
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2; /* Place the video above the image */
    overflow: hidden;
  }


  
  
  /* Default styles for the header content */
  header .cta {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #FFF;
    text-align: center;
    
    /* H1 */
    font-family: "Archive";
    font-size: 39px;
    font-style: thin;
    font-weight: 200;
    line-height: 1;
    letter-spacing: 14px;
    position: relative; /* Add this line */
    z-index: 3; 

  }


  
  /* Style the logo */
  .navbox {
    position: fixed;
    top: 2vh;
    right: 3vh;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    height: 40.999px;
    flex-direction: column;
}





 
  
  /* Styles for small screens (e.g., screens less than 768px wide) */
  @media screen and (max-width: 768px) {


    .header-navigation-tabs{
      display: none;

    }


    header .cta {
        font-size: 38px;
        letter-spacing: 5px;

    }
    header .background-image {
        display: none;
    }
    
    /* Adjust styles for video container on small screens */
    header .video-container {
        padding-top: 0; /* Remove aspect ratio padding for small screens */
        height: 100vh; /* Full height of the screen */
    }



    .logo {
      margin-top: 11px;
      height: 2.5rem;
      padding: 3px;
      margin-left: -1rem;
      position: sticky;

    }
    .logo-container {
      width: 91%;
      display: grid;
      grid-template-columns: 0fr 2fr 0.4fr;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      top: 1.4rem;
      height: 6rem; /* Adjust the height based on your logo's size */
      z-index: 4; /* Ensure the logo is on top */
      padding: 1rem;
    
    }

    .menu-icon {
      height: 6rem; /* Adjust the height based on your logo's size */
      margin-right: -2rem;
      margin-top: 1px;
      padding: 0.8rem;
      font-size: 4rem;
      color: whitesmoke;
      position: sticky;
    }

    .menu {
      font-family: "Bruno";
      top: 7rem;
      right: 2.5rem;
      background-color: rgb(224, 224, 224);
      padding: 1.2rem;
      box-shadow: 14px -10px 18px rgba(0, 0, 0, 0.1);
      border-radius: 1rem 1rem 1rem 1rem;
      text-decoration: none;
      color: rgba(51, 46, 46, 0);
    }
    .menu li {
      font-size: 1.5rem;
      padding: 2rem;
      color: none;
      text-decoration: none;
      text-align: center;
    }
    .menu a {
      text-decoration: none;
      color: #2B2833;
    }

    header video {
        max-width: 100%; /* Ensure video doesn't exceed the viewport width */
        height: 100%; /* Full height of the video container */
        object-fit: cover; /* Adjust object-fit based on your preference */
    }

}
  
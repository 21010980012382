

body {
  margin: 0;
  padding: 0;
}


.footer {
  width: 100%;
  padding-block: 1.5rem;
  position: relative;
  font-family: "Archive";
  background-color: #0F2127;
  
  
}

.footer-box {
  width: 95%;
  margin: 0 auto;
  padding: 1rem;
  display: grid;
  grid-template-columns: 2.33fr repeat(3, 1fr) 2fr;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  margin-top: 2rem;
  color: #ffffff;
  background-color: #0F2127;
  margin-right: 1rem;
  
  
}

.footer-box-social{
  align-items: left;
}

.footer-social{
  display: flex;
  gap: -2rem;
  align-items: center;
  

}
.footer-logo{
  height: 6.333vh;
}

.footer-logo-box{
  display: flex;
  justify-content: space-between;

}

.footer-logo-text {
  font-size: 2rem;
  margin-left: 2rem;
  font-family: "Bruno";
  font-style: normal;
  
  

}

.footer-signup-box{
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #4B6D97;
  background-image: linear-gradient(to right, #4B6D97, #8da6c3);
  
}

.footer-signup input{
  background-color: transparent;
  border: none;
  outline: none;
  width: 80%;
  color: #ffffff !important;
  all: unset;
  caret-color: #ffffff !important; /* Set caret color to white */
  

}

.footer-signup input::placeholder {
  color: #ffffff !important;
}

.footer-signup{
  color: #ffffff;

}

.footer-information{
  padding: 1rem ;
  list-style: none;
  margin-top: -1.5rem;
}
.footer-information a {
  color: inherit; /* Inherit color from parent */
  text-decoration: none; /* Remove underline */
}


@media screen  and (max-width: 45em) {
  .footer-box {
    width: 90%;
    grid-template-columns: 1fr 2fr;
    margin-top: 1rem;
    align-items: center;


  }  
  .footer-signup-box{
    align-items: center;
  }
  .footer-signup-box{
    align-items: center;
  }
  .footer-box-social{
    align-items: center;
  }
  .footer-information{
    padding: 0;
    list-style: none;
    margin-top: -3rem;
  }

}
@media screen  and (max-width: 35em) {
  .footer-box {
    width: 90%;
    grid-template-columns: 1fr;
  }
  .footer-signup-box{
    align-items: center;
  }
  .footer-signup-box{
    align-items: center;
  }
  .footer-box-social{
    align-items: center;
  }
}

.watermark-box {
  display: flex;
  justify-content: center;
  align-items: center;
  
  margin: 0;
  padding: 10px;
  color: #ffffff;
  background-color: #4B6D97;
  padding: 0.5rem;
  
  
}

.watermark-copyright{
  padding: 0rem;
  color: #ffffff;
  font-size: 0.5rem;
  margin: 0.33rem;
  margin-top: 0.6rem;
  display: flex;
  justify-content: center;

  
}

.watermark {

  padding: 13rem;
  display: grid;
  justify-content: center;
  align-items: center;
  


}



.follow-links {
  margin-left: 10px;
  margin-bottom: 1vh;
  

}

.icons {
  list-style: none;
  padding: 2vh;

}

.icon-footer {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  color: #9098b792;
  list-style: none;
}

* p2 {
  font-family: "SpaceGrotesk";
  font-style: normal;
  font-size: 19px;
  margin-left: 27vh;
}
* p3 {
  
  font-family: "Bruno";
  font-style: normal;
  font-size: 10px;

}

.information { 
  color: #ffffff;
  list-style: none;
  display: flex;
  justify-content: left;


}

footer .cta { 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: bottom; /* Center text within the container */
    color: #ffffff4f;
    top: 35vh;
    /* H1 */
    font-family: "Archive";
    font-size: 18px; /* Adjust the font size to your preference */
    font-style: normal;
    font-weight: 100;
    line-height: 0.2; /* Use a unitless value for better control */
    letter-spacing: 6px; /* Adjust the letter spacing to your preference */
}


  /* MOTHER LOGO footer non-mobile default */
  .footerlogo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: reverse;
    height: 2vh;
    padding: 4vh;
    
  }

  .mothersvg{
    height: 6.333vh;
    margin-bottom: 1vh;
  }

  @media screen and (max-width: 768px) {


    .mothersvg{
      height: 5vh;
    }

    /* MOTHER LOGO footer mobile only */
    .footerlogo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 2vh;
      padding: 0;

    } 

    /* ICONS footer mobile only */
    .follow-links {
      margin-left: 6px;
      margin-right: 6px;
      margin-bottom: 2px;
    }

    * p2{
      font-family: "SpaceGrotesk";
      font-style: italic;
      font-size: 20px;
      margin-left: 27vh;
      margin-right: 2vh;
      margin-bottom: 2em;
    }

  }

  
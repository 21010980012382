:root {
  --green-leaf-color: #51A8B4;
  --green-dark-color: #51A8B4;
  --blue-pastel-color: #929CA9;
  --yellow-pale-color: #87a0da;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}



/* Define your font-face rules here */

@font-face {
  font-family: "Bruno";
  src: url("../public/fonts/brunoacesc-regular-webfont.woff2") format("woff2"),
    url("../public/fonts/brunoacesc-regular-webfont.woff") format("woff");
  font-style: normal;
  font-weight: 400;
  letter-spacing: 33.6px;
}

@font-face {
  font-family: "Archive";
  src: url("../public/fonts/Archivo-ExtraLight.woff2") format("woff2"),
    url("../public/fonts/Archivo-ExtraLight.woff") format("woff"),
    url("../public/fonts/Archivo-ExtraLight.ttf") format("ttf");
  font-style: normal;
  font-weight: 400;
  letter-spacing: 33.6px;
}

@font-face {
  font-family: "Archive";
  src: url("../public/fonts/Archivo-ExtraLightItalic.woff2") format("woff2"),
    url("../public/fonts/Archivo-ExtraLightItalic.woff") format("woff"),
    url("../public/fonts/Archivo-ExtraLightItalic.ttf") format("ttf");
  font-style: italic;
  font-weight: 400;
  letter-spacing: 33.6px;
}

@font-face {
  font-family: "SpaceGrotesk";
  src: url("../public/fonts/SpaceGrotesk-Light.woff2") format("woff2"),
    url("../public/fonts/SpaceGrotesk-Light.woff") format("woff"),
    url("../public/fonts/SpaceGrotesk-Light.ttf") format("ttf");
  font-style: normal;
  font-weight: 300;
  letter-spacing: 33.6px;
}

@font-face {
  font-family: "SpaceGrotesk";
  src: url("../public/fonts/SpaceGrotesk-Bold.woff2") format("woff2"),
    url("../public/fonts/SpaceGrotesk-Bold.woff") format("woff"),
    url("../public/fonts/SpaceGrotesk-Bold.ttf") format("ttf");
  font-style: bold;
  font-weight: 400;
  letter-spacing: 33.6px;
}

@font-face {
  font-family: "bruno-ace";
  src: url("https://fonts.googleapis.com") format("woff2"),
    "https://fonts.gstatic.com" format("woff"),
    "https://fonts.googleapis.com/css2?family=Bruno+Ace+SC&display=swap" format("ttf");
    font-family: "Bruno Ace SC", sans-serif;
    font-weight: 400;
    font-style: normal;

}

@font-face {
  font-family: "DarkerGrotesque";
  src: url("https://fonts.googleapis.com") format("woff2"),
  "https://fonts.gstatic.com" format("woff"),
    url("https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@300..900&display=swap") format("ttf");
  font-family: "Darker Grotesque", sans-serif;
}





/* Apply the font-family to the body */
body {
  margin: 0;
  font-family: "Archive";
}

.wrapper {
  max-width: 1024px;
  margin: 0;
}

a {
  text-decoration: none;
}

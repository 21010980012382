waitlist {
    min-height: 100vh;
    max-width: 100%;
    background: url('../../assets/sai_product_wide.jpg');
    background-size: cover;
    background-position: center;
    overflow-x: hidden;
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
}




waitlist .cta { 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Center text within the container */
    color: #a1a1a1;
    text-align: left;
    padding-left: 20px;
    
    /* H1 */
    font-family: "SpaceGrotesk";
    font-size: 62px; /* Adjust the font size to your preference */
    font-style: normal;
    font-weight: 200;
    line-height: 1.2; /* Use a unitless value for better control */
    letter-spacing: 15px; /* Adjust the letter spacing to your preference */

    @media screen and (max-width: 768px) {
      letter-spacing: 4px;
      font-size: 48px;
      margin-bottom: 0.5em;
      margin-top: -1em;
    }
}

 /* Styles for small screens (e.g., screens less than 768px wide) */
@media screen and (max-width: 768px) {

    waitlist {

      background-position: 88%;
    }
    waitlist .cta {
        font-size: 52px;
        letter-spacing: 20px;
        text-align: center;

    }

    waitlist .mobile-icon {

        margin-left: 1.4em;
        display: flex;
        margin-right: 2em;
    }



}



form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {

    align-items: center;
  }
}

input[name="email"] {

    outline: none;
    border: none;
    background-color: #1978E6;
    border-radius: 6px;
    font-size: 18px;
    height: 100%;
    width: 24em;
    padding: 0.5em 2em 0.5em 2em;
    font-family: "SpaceGrotesk";
    font-style: italic;
    font-weight: 200;
    /* margin-right: 1em; */
  
    &::placeholder {
  
      color: #ffffff51;
    }


}

@media screen and (max-width: 768px) {

  input[name="email"] {

    display: flex;
    justify-content: center;
    font-size: 17px; 
    margin-left: 10%;
    width: 80%;
    max-width: 90vw;
    padding: 0.555em 0em 0.6em 1.444em;



  }
  button[name="join-button"] {

    margin-top: 1em;
    padding-right: 50em;
    display: flex;
    margin-left: 55%;
    width: 35% !important;
  

  }
}
  

button[name="join-button"] {

    color: #000000;
    text-align: center;

    width: 30vh;


    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 98.3%; /* 17.694px */
    letter-spacing: 4px;
    border-radius: 6px;

    padding-left: 2em;
    padding-right: 2em;
    cursor: pointer;
    transition: all 300ms ease-in-out;
    padding-top: 0.55em;
    padding-bottom: 0.55em;
    font-family: "Archive";
    font-style: bold;

  &:hover {
    top: -1.2px;
    border-radius: 10px;
    
    background-color: #2b00ff;
    border: 1px solid #ffffff;
    color: #ffffff;

  }


}

